.button {
    border-radius: 500px;
    padding:       4px 24px;
    cursor:        pointer;
    display:       inline-flex;
    border:        2px solid transparent;
    align-items:   center;
    transition:    filter 200ms ease-in-out;
}

.button:hover {
    filter: brightness(80%);
}

.primary {
    background-color: theme('colors.brand-purple');
    border-color:     theme('colors.brand-purple');
    color:            white;
    font-weight:      600;
}

.primaryOutline {
    background-color: transparent;
    border-color:     theme('colors.brand-purple');
    color:            theme('colors.brand-purple');
    font-weight:      600;
}

.primaryOutline:hover {

}

.secondary {
    background-color: white;
    border-color:     white;
    font-weight:      600;
}

.text {
    font-size:    0.9rem;
    line-height:  0.9rem;
    margin-right: 8px;
    white-space:  nowrap;
    color:        inherit;
}

.icon {
    margin-bottom: 1.5px;
}